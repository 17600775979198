import React from 'react';
class Header extends React.Component{
    constructor(){
        super();
         this.state = {st : ''}
    }
    toggleOk(e){
        this.setState({st : 'active'})

    }
    toggleCancel(e){
        this.setState({st : ''})
    }

    render(){
        return(
            <div>
             <header  className="header">
                <div  className="header-top">
                    <div  className="container">
                        <div  className="row">
                            <div  className="col-lg-6 align-self-center">
                                <div  className="top-info-left">
                                    <span><i  className="fas fa-map-marker-alt"></i> Babhaleshwar, Ahmednagar , Maharashtra.</span>
                                    <span><i  className="far fa-envelope"></i> support@srwsoftwares.com</span>
                                </div>
                            </div>
                            <div  className="col-lg-6 align-self-center text-right">
                                <div  className="top-info-right">
                                    <div  className="office-time">
                                        <span><i  className="far fa-clock"></i> Office Hour : 09:00am - 6:00pm</span>
                                    </div>
                                    <div  className="top-social">
                                        <ul>
                                            <li>
                                                <a href="javascript:void(0);"><i  className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);"><i  className="fab fa-twitter"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);"><i  className="fab fa-instagram"></i></a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(0);"><i  className="fab fa-linkedin"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="header-bottom">
                    <div  className="container">
                        <div  className="row">
                            <div  className="col-lg-4 col-xl-4 col-6">
                                <div  className="logo">
                                    <a href="/">
                                        <img src="assets/img/logo.png" alt="logo" />
                                    </a>
                                </div>
                            </div>
                            <div  className="col-lg-8 col-xl-8 col-6">
                
                                <div  className="header-search">
                                    
                                    <div  className="header-top-search-form">
                                        <div  className="header-top-search-form-full">
                                            <form action="#">
                                                <input type="search" name="search" placeholder="Search Here.." />
                                                <button type="submit"><i  className="fas fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div  className="canvas_open">
                                    <a href="javascript:void(0);" onClick={(e)=>this.toggleOk(e)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </a>
                                </div>
                            
                                <div  className="menu">
                                    <nav>
                                        <ul>
                                            <li>
                                                <a href="/">Home</a>
                                            </li>
                                            <li>
                                                <a href="/about-us">About Us</a>
                                            </li>
                                            
                                            <li>
                                                <a href="javascript:void(0);">Services</a>
                                                <ul  className="sub-menu">
                                                <li><a href="/website-webapplication">Website / Web Application</a></li>
                                                <li><a href="/ui-ux-developement">Mobile UI/UX</a></li>
                                                <li><a href="/blockchain">Blockchain</a></li>
                                                <li><a href="/seo-marketing">SEO Marketing</a></li>
                                                <li><a href="/consultancy-services">Consultancy</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <a href="/careers">Careers</a>
                                            </li>
                                            <li><a href="/contact">Contact Us</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="mobile-menu-area">
            <div className={"off_canvars_overlay " + (this.state.st ? 'active' : '') }></div>
            <div className="offcanvas_menu">
                <div  className={"offcanvas_menu_wrapper " + (this.state.st ? 'active' : '') }>
                    <div className="canvas_close">
                        <a href="javascript:void(0);" onClick={(e)=>this.toggleCancel(e)}><i className="fas fa-times"></i></a>
                    </div>
                    <div className="mobile-logo">
                        <a href="/">
                            <img src="assets/img/logo.png" alt="logo" />
                        </a>
                    </div>
                    <div id="menu" className="text-left">
                        <ul className="offcanvas_main_menu">
                            <li className="menu-item-has-children active">
                                <a href="/">Home</a>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="/about-us">About Us</a>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="#">Services</a>
                                <ul className="sub-menu">
                                    <li><a href="/website-webapplication">Website / Web Application</a></li>
                                    <li><a href="/ui-ux-developement">Mobile UI/UX</a></li>
                                    <li><a href="/blockchain">Blockchain</a></li>
                                    <li><a href="/seo-marketing">SEO Marketing</a></li>
                                    <li><a href="/consultancy-services">Consultancy</a></li>
                                </ul>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="/careers">Careers</a>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="/contact"> Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

            </div>
        )
      
    }
}

export default Header;