import React from 'react'
import Footer from './Footer';
import Header from './Header';
import Breadcumb from './Breadcumb';
class Careers extends React.Component {
    render() {
        return (
            <div>
                <Header  name="Careers | Srw Softwares"/>
                <Breadcumb name="Careers" />
                <section className="about-area section-padding section-bg">
                    <div className="container">
                         <div  className="row">
                            <div className="col-lg-12 text-center">
                              
                                        <p>For Job/training :</p>
                                        careers@srwsoftwares.com
                                        <hr />
                                        <p>For Technical Support :</p>
                                        support@srwsoftwares.com
                            </div>
                         </div>
                        
                      
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}
export default Careers;