import React from 'react'
import Header from './Header';
import Footer from './Footer';
import Breadcumb from './Breadcumb';

class Services extends React.Component {
    constructor(){
        super()
    }
    render() {
        return (
            <div>
                <Header name="Services | Srw Softwares"/>
                <Breadcumb name="Services" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-50 text-center">
                                <div className="section-headding">
                                    <h5>Our Services</h5>
                                    <h2>
                                        Our IT Solutions &amp; Services for <br />
                                        Your Business
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">01</div>
                                        <div className="icon">
                                            <i className="fas fa-mobile-alt"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">Mobile Devlopment</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">02</div>
                                        <div className="icon">
                                            <i className="fas fa-pencil-ruler"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">UI UX Design</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">03</div>
                                        <div className="icon">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">Digital Marketing</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">04</div>
                                        <div className="icon">
                                            <i className="fas fa-code"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">Web Devlopment</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">05</div>
                                        <div className="icon">
                                            <i className="far fa-thumbs-up"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">IT Management</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-1">
                                    <div className="info-box-1-inner">
                                        <div className="number-c">06</div>
                                        <div className="icon">
                                            <i className="fas fa-chart-line"></i>
                                        </div>
                                        <div className="content">
                                            <h3><a href="services-details.html">Business Security</a></h3>
                                            <p>Arose mr rapid in so vexed words. Gay welcome led add to lasting chiefly say to looking for better.</p>
                                            <a href="services-details.html" className="link">Read Details</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}
export default Services;