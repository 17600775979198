import React from 'react'
import Header from './Header';
import Breadcumb from './Breadcumb';
import Footer from './Footer';
class Website extends React.Component {
    render() {
        return (
            <div>
                <Header name="Website / Web Application Development"/>
                <Breadcumb name="Website / Web Application Development" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="services-details">
                                    <div className="thumbnail">
                                        {/* <img src="assets/img/services-details.jpg" alt="img" /> */}
                                    </div>
                                    <div className="content">
                                        <h2>Website Development</h2>
                                        <p>
                                        Website is a collection of related web pages that contains images, text, audio, video, etc. 
                                        </p>
                                       <p>
                                       It can be consist of one page, two pages, and n number of pages.
                                        A website provides visual and text content that users can view and read.
                                        To view a website requires a browser(Chrome, Firefox). There are many types of websites like Archive website, Blog, Community website, etc.
                                       </p>
                                       
                                       <h2>Web Application</h2>
                                       <p>A web application is a software or program which is accessible using any web browser. Its frontend is usually created using languages like HTML, CSS, Javascript, which are supported by major browsers. While the backend could use any programming stack like LAMP, MEAN, etc. Unlike mobile apps, there is no specific SDK for developing web applications.</p>

                                       <p>Web Applications came to prominence with the advent of Software as a Service (SaaS) movement.</p>

                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-single-wi mb-30">
                                    <div className="services-wi-category">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> IT Consultancy</a>
                                            </li>
                                            <li>
                                                <a  href="#"><i className="fas fa-angle-double-right"></i> Digital Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Advisory Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Data Structuring</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Experience Design</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Content Engineering</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-single-wi">
                                    <img src="assets/img/sidebar-img-one.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Website;