import React from 'react'
import Header from './Header';
import Breadcumb from './Breadcumb';
import Footer from './Footer';
class Blockchain extends React.Component {
    render() {
        return (
            <div>
                <Header name="Blockchain Development" />
                <Breadcumb name="Blockchain Development" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="services-details">
                                    <div className="thumbnail">
                                        {/* <img src="assets/img/services-details.jpg" alt="img" /> */}
                                    </div>
                                    <div className="content">
                                        <h2>What is Blockchain?</h2>
                                        <p>A blockchain is “a distributed database that maintains a continuously growing list of ordered records, called blocks.” These blocks “are linked using cryptography. Each block contains a cryptographic hash of the previous block, a timestamp, and transaction data. A blockchain is a decentralized, distributed and public digital ledger that is used to record transactions across many computers so that the record cannot be altered retroactively without the alteration of all subsequent blocks and the consensus of the network.”</p>
                                        
                                        <h4>What are the business benefits of blockchain?</h4>

                                        <p>The primary benefit of blockchain is as a database for recording transactions, but its benefits extend far beyond those of a traditional database. Most notably, it removes the possibility of tampering by a malicious actor, as well as providing these business benefits:</p>

                                        <p><strong>Time savings.    </strong>Blockchain slashes transaction times from days to minutes. Transaction settlement is faster because it doesn't require verification by a central authority.</p>
                                        <p><strong>Cost savings.    </strong>Transactions need less oversight. Participants can exchange items of value directly. Blockchain eliminates duplication of effort because participants have access to a shared ledger.</p>
                                        <p><strong>Tighter security.</strong> Blockchain's security features protect against tampering, fraud, and cybercrime.</p>


                                        <h2>Blockchain and Hyperledger</h2>

                                        <p>Hyperledger is “an umbrella project of open source blockchains and related tools, started in December 2015 by the Linux Foundation and supported by industry players like IBM, Intel and SAP to support the collaborative development of blockchain-based distributed ledgers.”</p>

                                        <p>Hyperledger participants believe that “only an Open Source, collaborative software development approach can ensure the transparency, longevity, interoperability and support required to bring blockchain technologies forward to mainstream commercial adoption.”</p>

                                        <p>The objective of the Hyperledger project “is to advance cross-industry collaboration by developing blockchains and distributed ledgers, with a particular focus on improving the performance and reliability of these systems (as compared to comparable cryptocurrency designs) so that they are capable of supporting global business transactions by major technological, financial and supply chain companies.”</p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-single-wi mb-30">
                                    <div className="services-wi-category">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> IT Consultancy</a>
                                            </li>
                                            <li>
                                                <a  href="#"><i className="fas fa-angle-double-right"></i> Digital Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Advisory Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Data Structuring</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Experience Design</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Content Engineering</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-single-wi">
                                    <img src="assets/img/sidebar-img-one.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Blockchain;