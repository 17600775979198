import React from 'react';

class Loader extends React.Component{
    constructor(){
        super();
    }
    render(){
        return(
            <div id="preloader">
                <div className="preloader">
                    <span></span>
                    <span></span>
                </div>
             </div>
            
            )
    }
}

export default Loader;