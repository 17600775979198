import React from 'react'
import Header from './Header';
import Breadcumb from './Breadcumb';
import Footer from './Footer';
class Ux extends React.Component {
    render() {
        return (
            <div>
                <Header name="UX/UI Development" />
                <Breadcumb name="UX / UI Development" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="services-details">
                                    <div className="thumbnail">
                                        {/* <img src="assets/img/services-details.jpg" alt="img" /> */}
                                    </div>
                                    <div className="content">
                                        <h2>UI/UX Development</h2>
                                        <p>User interface (UI) and user experience (UX) are two words that you might hear mentioned frequently in tech circles (and sometimes interchangeably). But what do the terms actually mean, and what does it mean to be a UX or UI designer?</p>
                                        <p>UI refers to the screens, buttons, toggles, icons, and other visual elements that you interact with when using a website, app, or other electronic device. UX refers to the entire interaction you have with a product, including how you feel about the interaction. While UI can certainly have an impact on UX, the two are distinct, as are the roles that designers play.</p>
                                        User Interface (UI) — A specialization of web design that deals with the controls people use to interact with a website or app, including button displays and gesture controls.
                                        User Experience (UX) — Another specialization of web design, this one dealing with user behavior and feeling when using the site or app. UX design encapsulates many other areas, but views them from the perspective of the user.

                                        As you can see already, none of these areas are exclusive and there's tons of overlap. Web design and development are just two sides of the same coin, UI design influences UX design, web development supports them all… it's less about which fields handle which tasks, and more about how each field considers the same task from a different point-of-view.

                                        For example, let's look at loading times, a common problem for every website. How does each field address loading times:

                                            <p>Web design: If a page takes too long to load, there's either too much content or content that's too complex. The image files can be compressed, assets can be adjusted & re-exported and  pages can be trimmed of excess content.</p>
                                            <p>Web development: To make content load faster, we can try better file compression to reduce the file sizes of the content, CSS sprites to save bandwidth or a content delivery network to improve loading times in specific geographical regions.</p>
                                            <p>UI: Controls must be as responsive as possible, so the interface must be simple enough that interactivity is instantaneous.</p>
                                            <p>UX: The likelihood a user will “bounce” (leave your site after just a few moments) increases with every second of loading time, so we should prioritize reducing the load time on the home and landing pages first before addressing the problem site-wide.</p>

                                        <strong>In a perfect world, you would hire a specialist or team of specialists for each of these fields so you have an expert looking at your website from all angles. However, budgetary constraints often mean choosing one field over the other, or hiring freelancers on a project-by-project basis.</strong>

                                        <p>Occasionally you'll find someone who claims to do it all:</p>

                                            <p>Designers who can code sometimes label themselves as the all-in-one package, but in reality they're more limited than two separate specialists (though sometimes this might be a smart hire if you have a simple site).</p>
                                            <p>UI designers have many overlapping skills with web designers, so some people will use those titles interchangeably.</p>
                                            <p>UX and UI are often lumped together, considering they're both sub-specializations.</p>
                                            <p>UX is often treated as a skill in other professions, even outside of design, such as a product management.</p>

                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-single-wi mb-30">
                                    <div className="services-wi-category">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> IT Consultancy</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Digital Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Advisory Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Data Structuring</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Experience Design</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Content Engineering</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-single-wi">
                                    <img src="assets/img/sidebar-img-one.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Ux;