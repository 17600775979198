import React from 'react'
import Footer from './Footer';
import Header from './Header';
import Breadcumb from './Breadcumb';
class About extends React.Component {
    render() {
        return (
            <div>
                <Header  name="About Us | Srw Softwares"/>
                <Breadcumb name="About Us" />
                <section className="about-area section-padding section-bg">
                    <div className="container">
                         <div  className="row">
                            <div className="col-lg-12 text-center">
                                            <p>SRW Software is a expert in delivering end-to-end software development services. 
                                            The company specializes in the latest technologies including PHP,MYSQL,JAVASCRIPT,REACT,REACT-NATIVE,MONOGDB has been invariably recognized for its leading solutions, excellent execution, and strategic insights. 
                                            SRW's primary goal is to help all its clients to overcome the barriers of technology complexity and budget with the help of its unique offshore software development services.
                                            </p>
                            </div>
                         </div>
                         <hr />
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-thumbnail">
                                    <img src="assets/img/about.jpg" alt="about" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="about-content">
                                    <h5>About Our Company</h5>
                                    <h2>Logik Award Winning Digital Solutions</h2>
                                    <div className="about-content-navs-tab">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="aboutus-tab" data-bs-toggle="tab" data-bs-target="#aboutus" type="button" role="tab" aria-controls="aboutus" aria-selected="true">About Us</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="mission-tab" data-bs-toggle="tab" data-bs-target="#mission" type="button" role="tab" aria-controls="mission" aria-selected="false">Our Mission</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision" type="button" role="tab" aria-controls="vision" aria-selected="false">Our Vision</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="aboutus" role="tabpanel" aria-labelledby="aboutus-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>8</h2>
                                                        <span>Years of Experience</span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> UI/UX Design Journey</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="mission" role="tabpanel" aria-labelledby="mission-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>20</h2>
                                                        <span>More Than UI/UX </span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> Holistic Web Solutions</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>16</h2>
                                                        <span>Delivering Consistent Promises </span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> Holistic Web Solutions</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )

    }
}
export default About;