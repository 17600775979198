import React from 'react'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Website from './Website';
import Ux from './Ux';
import Blockchain from './Blockchain';
import Seo from './Seo';
import Consultant from './Consultant';
import Careers from './Careers';
class App extends React.Component{
   render(){
    return(
         <BrowserRouter>
           <Routes>
              <Route path="/"   element={<Home />}></Route>
              <Route path="/about-us" element={<About />}></Route>
              {/* <Route path="/services" element={<Services />}></Route> */}
              <Route path="/website-webapplication" element={<Website />}></Route>
              <Route path="/ui-ux-developement" element={<Ux />}></Route>
              <Route path="/blockchain" element={<Blockchain />}></Route>
              <Route path="/seo-marketing" element={<Seo />}></Route>
              <Route path="/consultancy-services" element={<Consultant />}></Route>
              <Route path="/careers" element={<Careers />}></Route>
              <Route path="/contact" element={<Contact />}></Route>
           </Routes>
         </BrowserRouter>
    )
      
   }
}
export default App;
