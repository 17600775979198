import React from "react";

class Breadcumb extends React.Component{
    render(){
        return(
            <div>
                 <div className="breadcrumb-area" style={{backgroundImage: "url('assets/img/breadcrumb.jpg')"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-content text-center">
                                    <h2>{this.props.name}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Breadcumb;