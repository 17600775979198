import React from 'react'
import Header from './Header';
import Breadcumb from './Breadcumb';
import Footer from './Footer';
class Consultant extends React.Component {
    render() {
        return (
            <div>
                <Header name="Consultancy Services"/>
                <Breadcumb name="Consultancy Services" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="services-details">
                                    <div className="thumbnail">
                                        <img src="assets/img/services-details.jpg" alt="img" />
                                    </div>
                                    <div className="content">
                                    <h2>What is consulting?</h2>

                                    <p>For professionals working in the professional services, the terms 'consultant' and advisor' are often used interchangeably. What exactly do these terms mean? Consultancy.in zooms in on this field to get a closer look at the origins of the consulting industry, and also provides insights into the main features of consultancy and the ecosystem in which consultants operate.</p>

                                    <p>Consulting is defined as the practise of providing a third party with expertise on a matter in exchange for a fee. The service may involve either advisory or implementation services. For the consultant, taking an independent and unbiased stance on an issue is central to his/her role. A consultant can, in principle, service any sector. Over the past few decades, however, the term has become synonymous with business advisory - which focuses mostly on business strategy, management, organisation, operational processes and technology.</p>

                                    <p>The history of the consulting industry commences in the late nineteenth century with the founding of the world's first modern consulting organisations, also known as business advisory firms. In the early days of the consulting industry (starting in the US and later crossing over to Europe and the rest of the world) the first consultancies focused their advisory services mainly on solving technical and financial concerns. Today, there are over 6000 consulting firms in India alone, set to grow up to 9000 firms worth approximately 27,000 crores by 2020. These firms offer all sorts of different services, spanning across countless fields, disciplines and sectors.
                                    Consultants' right to exist</p>

                                    <p>What do consulting firms base their right to exist on? One key feature of the industry is the so-called 'knowledge advantage' that consultancy firms hold in relation to their clients. Consultancy firms are hired to help their clients with a dilemma, for which specialist expertise or knowledge is needed which is otherwise lacking on the clients' end. Another feature is that consultancy firms form an independent party, which can give the clients an objective perspective on the problems that their organisation struggles with, and can offer them solutions toward the problem. Involving a consultancy firm can also, in some cases, be more cost effective than if an organisation were to hire experts themselves. Furthermore, an organisation often simply does not have sufficient capacity to manage its change portfolio, which can be done by hiring an external consultancy firm instead.</p>

                                    <p>Professionals working within the consultancy industry are generally called 'consultant' or 'advisor'. Often, specific titles are used referring to their knowledge of a certain advisory area or their respective sector of expertise (e.g. strategy consultant, healthcare consultant, procurement consultant, etc.)</p>

                                    <p>Even though the term consultant is not a protected title - in essence anyone could ascribe themselves the title of consultant from one day to the next - the consultants active within the industry are mostly highly educated professionals. They have gained their expertise in a particular field, or within a specific sector, after years of studying, training as well as by building work experience. From within their organisation, consultants are deemed to work with a professional demeanour, holding the right qualifications and capable of providing a high standard of service to clients. Consultants can work at a consultancy firm, operate as an independent consultant (freelance), or work as a consultant within an organisation outside the consulting industry ('internal consultant').
                                    Fields of expertise</p>

                                    <p>The consulting industry consists of six main functional areas: Strategy Consulting, Management Consulting, Operations Consulting, HR Consulting, Financial Advisory and IT Consulting. In total, these six advisory areas combine with other service areas to create more than 200 different fields of expertise and propositions.</p>
                                       
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-single-wi mb-30">
                                    <div className="services-wi-category">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> IT Consultancy</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Digital Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Advisory Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Data Structuring</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Experience Design</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Content Engineering</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-single-wi">
                                    <img src="assets/img/sidebar-img-one.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Consultant;