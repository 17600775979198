import React,{Fragment} from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Header from './Header';
import Loader from './Loader';
import Slider from './Slider';
import Footer from './Footer';

class Home extends React.Component {

    render() {
        return (
            <div>
                <Header  name="Explore your business | Srw Softwares"/>
                {/* <Loader /> */}
                <Slider />
                <section className="about-area section-padding section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-thumbnail">
                                    <img src="assets/img/about.jpg" alt="about" />
                                </div>
                            </div>
                            <div className="col-lg-6 align-self-center">
                                <div className="about-content">
                                    <h5>About Our Company</h5>
                                    <h2>Best Digital Solutions</h2>
                                    <div className="about-content-navs-tab">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="aboutus-tab" data-bs-toggle="tab" data-bs-target="#aboutus" type="button" role="tab" aria-controls="aboutus" aria-selected="true">About Us</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="mission-tab" data-bs-toggle="tab" data-bs-target="#mission" type="button" role="tab" aria-controls="mission" aria-selected="false">Our Mission</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="vision-tab" data-bs-toggle="tab" data-bs-target="#vision" type="button" role="tab" aria-controls="vision" aria-selected="false">Our Vision</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="aboutus" role="tabpanel" aria-labelledby="aboutus-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>8</h2>
                                                        <span>Years of Experience</span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> UI/UX Design Journey</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="mission" role="tabpanel" aria-labelledby="mission-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>20</h2>
                                                        <span>More Than UI/UX </span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> Holistic Web Solutions</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
                                                <div className="about-content-navs-tab-content-full">
                                                    <div className="title">
                                                        <h2>16</h2>
                                                        <span>Delivering Consistent Promises </span>
                                                    </div>
                                                    <p>We develop innovative and contemporary web and mobile apps which makes the world a smaller place through faster services.</p>
                                                    <ul>
                                                        <li><i className="far fa-check-square"></i> Holistic Web Solutions</li>
                                                        <li><i className="far fa-check-square"></i> Software Consulting</li>
                                                    </ul>
                                                    <a className="button-1" href="about.html">View Details</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="services-section section-padding-2">
                    <div className="container">
                        <div className="row">

                        <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fas fa-code"></i>
                                        </div>
                                        <div className="con">
                                            <h2>Website /Web Application</h2>
                                            <p>Are you ready to cross your Digital Transformation? We surf your digital wave by developing whatever solution you have in mind from scratch or by helping you enter the Web Universe with your Business. Our Team of passionate coding heroes knows whatever can impact drastically for digital product success, evolution, and quality evaluation.  </p>
                                            <a href="/website-webapplication">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            
                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fas fa-pencil-ruler"></i>
                                        </div>
                                        <div className="con">
                                            <h2>UI UX Design</h2>
                                            <p>Create the best user experience to any screen size regardless of the device. Your users will appreciate fast and clear navigation, easy transitions, seamless flow, and beautiful looks. Our approach to design is to solve user and business problems and make the experience of using your product delightful. </p>
                                            <a href="/ui-ux-developement">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fas fa-bitcoin"></i>
                                        </div>
                                        <div className="con">
                                            <h2>Blockchain Developement</h2>
                                            <p>
                                            Srw provides businesses with a complete view of the blockchain landscape and its potential implications. We support you at every step of blockchain adoption, from design thinking-driven evaluation to assessing blockchain solutions from various dimensions, creating proofs-of-concept, and pilot development to complete implementation of solutions. Our expertise.
                                            </p>
                                            <a href="/blockchain">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fas fa-bullhorn"></i>
                                        </div>
                                        <div className="con">
                                            <h2>SEO Marketing</h2>
                                            <p>
                                            Whether you have an in-house SEO team or are looking for an SEO expert or SEO consultant, I can help you accelerate growth with results-oriented SEO and content marketing strategies. 
                                            </p>
                                            <a href="/seo-marketing">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                           

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="far fa-thumbs-up"></i>
                                        </div>
                                        <div className="con">
                                            <h2>Consultancy Services</h2>
                                            <p>
                                            A technology partner for startups & enterprises seeking for innovation. We deliver end-to-end software products, from prototyping to design, development and post-launch support and maintenance.

                                            </p>
                                            <a href="/consultancy-services">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mb-30">
                                <div className="info-box-2">
                                    <div className="content-top">
                                        <div className="icon">
                                            <i className="fas fa-chart-line"></i>
                                        </div>
                                        <div className="con">
                                            <h2>Quality Assurance</h2>
                                            <p>
                                            We implement a Quality Management Process able to conduct a process review, create reports on project status, perform a process compliance review, identify process improvement areas, and create process training.

                                            </p>
                                            <a href="#">
                                                <i className="fas fa-long-arrow-alt-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div className="content-overly">
                                        <div className="content-overly-full">
                                            <h2>Preparing For Your Business Success With IT Solution</h2>
                                            <p>Mauris euismod metus non turpis consequat, eu volutpat massa gravida. Praesent in dapibus lorem, quis commodo eros.</p>
                                            <a href="product-details.html">Learn More</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="works-about-area section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 align-self-center">
                                <div className="works-about-content">
                                    <h5>Works About</h5>
                                    <h2>Trusted by 1200+ Happy Customers</h2>
                                    <p>
                                        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
                                        letters.
                                    </p>
                                    <ul>
                                        <li><i className="fas fa-check-circle"></i> 100% Client Satisfaction</li>
                                        <li><i className="fas fa-check-circle"></i> World Class Worker</li>
                                    </ul>
                                    <div className="btn-aa">
                                        <a className="button-2" href="project.html">Completed Projects</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 align-self-center">
                                <div className="works-about-thumbanil">
                                    <div className="thumbnail">
                                        <img src="assets/img/works-about.jpg" alt="img" />
                                    </div>
                                    <div className="works-about-vedio">
                                        <div className="video-btn">
                                            <a href="#"><i className="fas fa-play"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="counter-area pt-50 pb-20" style={{backgroundImage: "url('assets/img/counterbg.jpg')"}}>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6 mb-30">
                                <div className="counter-item">
                                    <div className="icon">
                                        <i className="fas fa-tasks"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h2 className="counter">201</h2>
                                            <h3>+</h3>
                                        </div>
                                        <p>Projects Completed</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mb-30">
                                <div className="counter-item">
                                    <div className="icon">
                                        <i className="fas fa-dna"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h2 className="counter">255</h2>
                                            <h3>+</h3>
                                        </div>
                                        <p>Remaining Project</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mb-30">
                                <div className="counter-item">
                                    <div className="icon">
                                        <i className="far fa-gem"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h2 className="counter">51</h2>
                                            <h3>+</h3>
                                        </div>
                                        <p>Business Start-up</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mb-30">
                                <div className="counter-item">
                                    <div className="icon">
                                        <i className="fas fa-user-tie"></i>
                                    </div>
                                    <div className="content">
                                        <div className="title">
                                            <h2 className="counter">6</h2>
                                            <h3>+</h3>
                                        </div>
                                        <p> Employer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
         </div>
        )

    }
}
export default Home;