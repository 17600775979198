import React from 'react'
import Breadcumb from './Breadcumb';
import Footer from './Footer';
import Header from './Header';
class Contact extends React.Component {
   render() {
      return (
         <div>
            <Header   name="Contact Us | Srw Softwares"/>
            <Breadcumb name="Contact Us" />
            <section className="contact-area section-padding">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-8 align-self-center">
                        <div className="contact-form">
                           <h2>Get In Touch</h2>
                           <form id="contact-form" action="#" method="POST">
                              <div className="row">
                                 <div className="col-md-6">
                                    <div className="single-input">
                                       <input type="text" name="name" placeholder="Your Name" />
                                       <i className="fas fa-user"></i>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="single-input">
                                       <input type="email" name="email" placeholder="Your Email" />
                                       <i className="far fa-envelope"></i>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="single-input">
                                       <input type="text" name="phone" placeholder="Your Phone" />
                                       <i className="fas fa-mobile-alt"></i>
                                    </div>
                                 </div>
                                 <div className="col-md-6">
                                    <div className="single-input">
                                       <input type="text" name="subject" placeholder="Your Subjects" />
                                       <i className="fas fa-file-alt"></i>
                                    </div>
                                 </div>
                                 <div className="col-12">
                                    <div className="single-input">
                                       <textarea name="message" placeholder="Write Message"></textarea>
                                       <i className="fas fa-pen"></i>
                                    </div>
                                 </div>
                                 <div className="col-12">
                                    <button type="button" className="button-1">Send Message</button>
                                 </div>
                              </div>
                           </form>
                           <p className="ajax-response"></p>
                        </div>
                     </div>
                     <div className="col-lg-4 align-self-center">
                        <div className="contact-form-info" style={{backgroundImage: "url('assets/img/contact.jpg')"}}>
                           <h2>Don't hesitate to contact us</h2>
                           <div className="contact-info-list">
                              <div className="item mb-20">
                                 <div className="icon">
                                    <i className="fas fa-home"></i>
                                 </div>
                                 <div className="content">
                                    <h4>Locations</h4>
                                    <p>A/p- Babhaleshwar Tal - Rahata Dist - Ahmednagar , Maharashtra</p>
                                 </div>
                              </div>
                              <div className="item mb-20">
                                 <div className="icon">
                                    <i className="far fa-envelope"></i>
                                 </div>
                                 <div className="content">
                                    <h4>Drop A Mail</h4>
                                    <p>support@srwsoftwares.com</p>
                                    <p>srwsoftware@gmail.com</p>
                                 </div>
                              </div>
                              <div className="item">
                                 <div className="icon">
                                    <i className="fas fa-mobile-alt"></i>
                                 </div>
                                 <div className="content">
                                    <h4>Call Us</h4>
                                    <p>0 8796 82 8796</p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            <Footer />
         </div>
      )

   }
}
export default Contact;