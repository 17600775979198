import React from 'react';

import  './sld.css';
import emailjs from '@emailjs/browser';

class Silder extends React.Component{
    constructor(props){
        super(props);
        this.state =  {
            name:'',
            email:'',
            ab:'',
            date:'',
        };
        this.form = React.createRef();
    }
    
    inputHandle(e){
        e.preventDefault();
        const value = e.target.value;
        const nm = e.target.name;
        this.setState({
            [nm] : value
        });

    }
    submitbutton(e){
        e.preventDefault();

        emailjs.sendForm('service_fz31orb', 'template_9oea9i4', this.form.current, 'qnUmNbEoqJ7yloRF0')
        .then((result) => {
            alert("Successfully Sent");
        }, (error) => {
            // console.log(error.text);
        });
       
    }
    render(){
        return(
            <div>

                <section className="hero-banner-area" style={{backgroundImage: "url('assets/img/hero.jpg')"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 align-self-center">
                                <div className="hero-banner-content">
                                    <h4>Empower your business</h4>
                                    <h2>Excellent IT services for your success</h2>
                                    <a className="button-1" href="/about-us">Learn More</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="hero-appointment-form">
                                    <h2>Make an Appointment.</h2>
                                    
                                    <form onSubmit={(e)=>this.submitbutton(e)} ref={this.form}>
                                        <input type="text" name="name" placeholder="Your Name"  value={this.state.name}  onChange={(e)=>this.inputHandle(e)} />
                                        <input type="email" name="email" placeholder="Your Email" value={this.state.email} onChange={(e)=>this.inputHandle(e)}/>
                                        <input type="text" name="ab" placeholder="Your Inquiry About"  value={this.state.ab} autoComplete='off' onChange={(e)=>this.inputHandle(e)}/>
                                        <input type="date" name="date" placeholder="Date" autoComplete='off'  value={this.state.date} onChange={(e)=>this.inputHandle(e)}/>
                                        <button type="button" className="button-2" onClick={(e)=>this.submitbutton(e)}>Appointment Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="themetechmount-area section-bg">
                    <div className="container">
                        <div className="themetechmount-area-full">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="themetechmount-item">
                                        <div className="icon">
                                            <i className="fas fa-cogs"></i>
                                        </div>
                                        <div className="content">
                                            <h4>Trusted Services</h4>
                                            <p>We Are Trusted Our Customers</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="themetechmount-item active">
                                        <div className="icon">
                                            <i className="fas fa-headset"></i>
                                        </div>
                                        <div className="content">
                                            <h4>24/7 Support</h4>
                                            <p>0 8796 82 8796</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6">
                                    <div className="themetechmount-item">
                                        <div className="icon">
                                            <i className="fas fa-user-astronaut"></i>
                                        </div>
                                        <div className="content">
                                            <h4>Expert &amp; Profe</h4>
                                            <p>We Are Trusted Our Customers</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default Silder;