import React from 'react';
 class Footer extends React.Component{
    constructor(){
        super();
    }
    render(){
        const a = 10;
        return(
            <div>
              <footer  className="footer">
                    <div  className="footer-top pt-70 pb-20">
                        <div  className="container">
                            <div  className="row">
                                <div  className="col-lg-3 col-md-6 mb-30">
                                    <div  className="footer-widgets-single">
                                        <h2  className="title">About Company</h2>
                                     
                                        <p>
                                        SRW Software is a  expert in delivering end-to-end software development services. 
                                        {/* The company specializes in the latest technologies including PHP,MYSQL,JAVASCRIPT,REACT,REACT-NATIVE,MONOGDB has been invariably recognized for its leading solutions, excellent execution, and strategic insights.  */}
                                        SRW's primary goal is to help all its clients to overcome the barriers of technology complexity and budget with the help of its unique offshore software development services.
                                        </p>
                                        <div  className="footer-call-btn">
                                            <div  className="icon">
                                                <i  className="fas fa-phone-volume"></i>
                                            </div>
                                            <div  className="con">
                                                <p>Talk To Our Support</p>
                                                <span>0 8796 82 8796</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div  className="col-lg-3 col-md-6 mb-30">
                                    <div  className="footer-widgets-single">
                                        <h2  className="title">Quick Links</h2>
                                        <ul>
                                            <li><a href="#">Home</a></li>
                                            <li><a href="/about-us">About Us</a></li>
                                            <li><a href="#">Careers</a></li>
                                            <li><a href="#">Services</a></li>
                                            <li><a href="/contact">Contact Us</a></li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div  className="col-lg-3 col-md-6 mb-30">
                                    <div  className="footer-widgets-single">
                                        <h2  className="title">Services</h2>
                                        <ul>
                                            <li><a href="#">Website/Web Application</a></li>
                                            <li><a href="#">Mobile UX/UI</a></li>
                                            <li><a href="#">Blockchain</a></li>
                                            <li><a href="#">SEO Marketing</a></li>
                                            <li><a href="#">Consultancy Services</a></li>
                                        </ul>
                                    </div>
                                </div>
                               
                                <div  className="col-lg-3 col-md-6 mb-30">
                                     <img src="assets/img/logo2.png" height={100}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className="footer-bottom pt-30 pb-30">
                        <div  className="container">
                            <div  className="row">
                                <div  className="col-12 text-center">
                                    <div  className="copy-text">
                                        <p>Copyright © 2023 by <a href="/">Srw Softwares</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
      
    }
}

export default Footer;