import React from 'react'
import Header from './Header';
import Breadcumb from './Breadcumb';
import Footer from './Footer';
class Seo extends React.Component {
    render() {
        return (
            <div>
                <Header name="SEO Marketing"/>
                <Breadcumb name="SEO Marketing" />
                <section className="services-area section-padding-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 mb-30">
                                <div className="services-details">
                                    <div className="thumbnail">
                                        <img src="assets/img/services-details.jpg" alt="img" />
                                    </div>
                                    <div className="content">
                                        <h2>What is SEO</h2>
                                        
                                         <p>At its core, the meaning of search engine optimization (SEO) is increasing your website's visibility in the organic search results of major search engines.</p>

                                        <strong>To get that visibility, you must understand three core components:</strong>
                                            <ul>
                                            <li>What types of content search engine users and your customers want or need.</li>
                                            <li>How search engines work to discover, index, and serve content in search engine results pages.</li>
                                            <li>How to properly promote and optimize your website to tell search engines more about it.</li>
                                            </ul>

                                        <p>While search engines and technology are always evolving, there are some underlying foundational elements that have remained unchanged from the earliest days of SEO.</p>
                                        <p>This is why, in collaboration with some of the field's top authorities and experts, we created these in-depth overviews and tutorials – to define SEO for aspiring SEO professionals and explain how search engine optimization really works now.</p>
                                        <p>How does SEO work? SEO is a fast-paced and dynamic field. It can also sometimes be frustrating, especially if you're relying on outdated tactics that no longer work.</p>
                                        <p>That's why it's crucial for you to stay well-informed and learn continuously. Search engines are always updating their algorithms to provide quality search results to their users.</p>
                                        <p>Artificial intelligence is constantly improving algorithms to ensure better user experiences. Meaning SEO is also more complex than ever today.</p>
                                        <p>Marketers must continue their SEO education to keep up with what tactics works now, and what tactics need to be removed from your search marketing plan.</p>
                                        <p>It takes more than just building links, creating any old content, and adding a few keywords to improve your organic search rankings and increase the visibility of your business or brand.</p>
                                        <p>You need to keep track of and understand:</p>

                                            <p>Emerging trends (e.g., voice search)</p>
                                            <p>New features in search related products and tools (e.g., Search Console)</p>
                                            <p>Technological advancements (e.g., machine learning).</p>
                                            <p>Your audience (e.g., how they behave and what they want).</p>

                                        <p>So how do you develop an SEO strategy to dominate your competition in Google and other search engines?</p>

                                        <p>Because, ultimately, SEO isn't only about being found on search engines and driving traffic to your website. It's about providing a great experience and generating leads and revenue.</p>

                                        <p>To create a positive user experience and generate leads from search, you have to do more than target the right keywords. You have to understand the intent of the search user and develop content that provides solutions for their problems.</p>

                                        <p>Once you understand your searcher's intent, you will be able to create content that meets the needs of search users and is optimized for search engines to discover and index it.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="services-single-wi mb-30">
                                    <div className="services-wi-category">
                                        <ul>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> IT Consultancy</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Digital Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Advisory Services</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Data Structuring</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Experience Design</a>
                                            </li>
                                            <li>
                                                <a href="#"><i className="fas fa-angle-double-right"></i> Content Engineering</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="services-single-wi">
                                    <img src="assets/img/sidebar-img-one.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        )
    }
}


export default Seo;